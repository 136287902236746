import './App.css';
import { Col, Row} from 'react-bootstrap';
import CarouselPrincipal from './components/carouselPrincipal';
import CarouselPrincipal2 from './components/carouselPrincipal2';
import { useState, useEffect} from 'react';
import { useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import DeviceIdentifier from 'react-device-identifier';
import AppMobile from './mobilePages/appMobile.js';
import Faqs from './pages/Faqs.js';
import fondoPrincipal from './images/fondoPrincipal.jpeg';
import faqsBackground from './images/faqsBackground.jpeg';

import Loading from './components/loading.js';






function App() {
  const [datos, setDatos] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    
    fetch(location.pathname)
      .then(res => res.json())
      .then(
        (res) => {
          setDatos(res);
          setIsLoaded(true);
        }
      )
  }, []);

  const agregarTipoCarousel = (datos, tipoCarousel)=>{
    let datosNuevos = [];
    if (tipoCarousel == "ventas"){
      for (let i = 0 ; i< datos.ventas.length; i +=1){
        datosNuevos.push(datos.ventas[i])
        datosNuevos[i]["tipoCarousel"] = "ventas"
      }
    }
    if (tipoCarousel == "arriendos")
    {
      for (let i = 0 ; i< datos.arriendos.length; i +=1){
        datosNuevos.push(datos.arriendos[i])
        datosNuevos[i]["tipoCarousel"] = "arriendos"
      }
      
      
    }
    return datosNuevos;

  }

  
  if(isLoaded){

  return (
    <div>
        <DeviceIdentifier isDesktop={true} isTablet={true} >
            <div id = "v2mainContainer">
                <div id="main_page_content_container">
			<div id = "main_page_superior_container">
			    <div id="cita">
				<h1 id="cita_h1">
				    Corretaje Propiedades, Ventas, Arriendos
				</h1>
				<h2 id="cita_h2">
				    Departamentos, Casas, Oficinas y Estacionamientos.
				</h2> 
				<hr id="cita_hr"/>
				<h5 id="cita_h3">
				    30 años de experiencia y dominio en materia legal. 
				</h5>
			    
			    
			    </div>
			    <div id= "carousel_arriendos_contenedor">
				<div className="the_wrapper">
					<div>

						<h1 className="carousel_h1">Arriendos Nuevos</h1>
						<CarouselPrincipal  
						datos = {agregarTipoCarousel(datos, "arriendos")}/>
						</div>
					</div>
				</div>	

			</div>

                </div>
		<div id = "fondoPrincipal" >
                	<img src={fondoPrincipal} />
		 	<div id = "main_page_middle_container">
				<div id = "mapa_main_page">

					<iframe  src="https://maps.google.com/maps?width=100%25&amp;height=800&amp;hl=en&amp;q=Padre%20Mariano%20210,%20providencia,%20Santiago+(Oficina)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" ></iframe>
				</div>
				<div id = "contenedor_direccion">
					<h1>Oficina</h1>
					<hr/>
					<h2>Padre Mariano 210, oficina 408</h2>
					<h3>Horario de atención: 09:30 - 18:00 hrs</h3>
					<h3>
						+56 2 2341 8815
					</h3>
					<h3>
						+56 2 2244 3634
					</h3>
				</div>
				<div id = "carousel_ventas_contenedor">
				<div className="the_wrapper2">
					<div>
						<h1 className="carousel_h1">Ventas Nuevas</h1>
						<CarouselPrincipal  
						datos = {agregarTipoCarousel(datos, "ventas")}/>
						</div>
					</div>
				</div>	

              
			
			</div>	
			<div id = "main_page_faq_container">
				<div id="main_page_faq_container_texto" >
            <Link style={{"textDecoration":"none", "color":"white"}} to ={"/api/faqs"}>
					<h1>
			
						Preguntas Frecuentes
				
					</h1>
					</Link>
				</div>
				<img src ={faqsBackground}/>
			</div>
		</div>
		
                <DeviceIdentifier isMobile={true} isTable={true}>
                <AppMobile datos = {datos}></AppMobile>

                </DeviceIdentifier>
            </div>
        </DeviceIdentifier>
        <DeviceIdentifier isMobile={true} >
        <AppMobile datos = {datos}></AppMobile>
    </DeviceIdentifier>
    </div>
  )
}else{
  return (
   <Loading></Loading>
  )
}
}

export default App;
