import {Carousel, Button, Row, Col, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useEffect } from 'react';


export default function CarouselPrincipal(props){




    


    const mostrarPropiedades = props.datos.map((propiedad, i)=>{
        return(

            <Carousel.Item key={i} className="carousel_principal" >

            <Link style={{"textDecoration":"none"}} to ={"/api/"+ propiedad.tipoCarousel +"/" + propiedad.nombre}>
		<div>
			<div className = "paginaInicialContenedorImagenAviso" >
			    <img
			    className="paginaPrincialImagenAviso"
			    src={propiedad.linkImagenPrincipal}
			    alt={i + " slide"}
			    key = {i}
			    
			    />
			</div>
			
			<div className="tarjeta_carousel">
				<div className="texto_carousel">
					<h3>{propiedad.nombre}</h3>
					<p>{propiedad.comuna}</p>
				</div>
				<div className="button_carousel">

				</div>
			</div>
		</div>
            </Link>
            </Carousel.Item>

        )
    })


    if (props.datos.length==0){
        return(
            <div className="contenedorNoDisponibleCarousel">
                <h4>Propiedades no disponibles</h4>
            </div>
        )
    }else {
        return(


            <Carousel  pause={"hover"} nextLabel={" "} prevLabel={" "} >
		
                {mostrarPropiedades}

            </Carousel>
        )
    }




}
