
import  { useState, useEffect, setState } from 'react';
import { Container, Modal, Row, Col, Button, Carousel } from 'react-bootstrap';

import { Link, useLocation , useParams} from 'react-router-dom';

import CarouselSubPagArriendo from '../components/carouselSubPagArriendo';
import SubPaginaPropiedadMobile from '../mobilePages/subPaginaPropiedadMobile.js'
import DeviceIdentifier from 'react-device-identifier';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Loading from '../components/loading.js';
import Mapa from '../components/mapa.js';
import { Helmet } from "react-helmet-async";






export default function SubPaginaPropiedadEstacionamiento() {
    let location = useLocation();
	const id= useParams();
    const [DatosPropiedad, setDatosPropiedad] = useState({});
    const [imagenesInterior, setImagenesInterior] = useState([]);
    const [imagenesExterior, setImagenesExterior] = useState([]);
    const [IsLoaded, setIsLoaded] = useState(false);
    const [lugaresInteres, setLugaresInteres] = useState([]);
    function nwc(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }




    useEffect(() => {
        fetch(location.pathname)
            .then(res => res.json())
            .then(
                (result) => {
                    setDatosPropiedad(result);
                    setImagenesInterior(result.linksImagenesInterior);

                    setLugaresInteres(result.listaLugaresInteres);
                    setImagenesExterior(result.linksImagenesExterior);
                    setIsLoaded(true);
                }
            )
	return ()=> console.log("unmount");
    }, []);

    const mostrarVideo = (DatosPropiedad) => {
        try {
            return (
		<>
                    <h1 id ="titulo_video">Video propiedad</h1>
                    <div className="subPaginaPropiedadContenedorVideo">

                        <iframe webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen id="video_iframe" src={definirDireccionVideo(DatosPropiedad)}  ></iframe>

                    </div>

                    <script src="https://player.vimeo.com/api/player.js"></script>
		</>

            )

        } catch (error) {
            return (<></>);
        }
    }

    const mostrarDormitorios = () => {
        if (DatosPropiedad.dormitorios == 1) {

	    if(DatosPropiedad.tipoPropiedad=="oficina"){
		return (

                <p>{DatosPropiedad.dormitorios} privado.</p>
		)		
	}


            return (
                <p>{DatosPropiedad.dormitorios} dormitorio</p>
            )

        } else {

	    if(DatosPropiedad.tipoPropiedad=="oficina"){
		return (
                <p>{DatosPropiedad.dormitorios} privados.</p>
		)		
	}
            return (
                <p>{DatosPropiedad.dormitorios} dormitorios</p>

            )

        }

    }

    function determinarSiHayExterior(imagenesExterior) {
        try {
            if (imagenesExterior[0].length != 0) {
                return <h1>Galería exterior</h1>
            } else return <></>
        } catch (error) {
            return <></>
        }
    }

    function determinarSiHayEstac(DatosPropiedad) {
        try {


            if (DatosPropiedad.estacionamientos != 0) {
		return <div className="info_div">Estacionamientos: {DatosPropiedad.estacionamientos}</div>
            } else return <></>
        } catch (error) {
            return <></>
        }

    }

    function determinarSiHayGCom(DatosPropiedad) {
        try {
            if (DatosPropiedad.gastosComunes != 0) {
		return <div className="info_div">Gastos comunes: ${nwc(DatosPropiedad.gastosComunes)}</div>
            } else return <></>
        } catch (error) { return <></> }
    }





    const mostrarBanos = () => {
        if (DatosPropiedad.banos == 1) {
            return (
                <p>{DatosPropiedad.banos} baño</p>
            )

        } else {
            return (
                <p>{DatosPropiedad.banos} baños</p>
            )

        }

    };
    const mostrarLugaresInteres = lugaresInteres.map((lugar, i) => {

        return (
            <li key={i} className="elementoListaLugares">{lugar}</li>
        );

    });
	const mostrar_galeria_interior = imagenesInterior.flat().map((imagen, i)=>{
			
	return(
		<div >
                        <Zoom>
                            <img className="imagen_galeria" src={imagen}></img>
                        </Zoom>
		</div>
	);

});

	const mostrar_galeria_exterior= imagenesExterior.flat().map((imagen, i)=>{
			
	return(
		<div >
                        <Zoom>
                            <img className="imagen_galeria" src={imagen}></img>
                        </Zoom>
		</div>
	);

});
    const definirDireccionVideo = (DatosPropiedad) => {
        const dv = DatosPropiedad.direccionVideo;
        if (dv.indexOf("os/") != -1) {
            let link = dv.substring(dv.indexOf("os/") + 3, dv.length)
            return ("https://player.vimeo.com/video/" + link + "?muted=1&portrait=0&byline=0&title=0")

        } else return dv;
    }


    if (IsLoaded) {
        return (
            <>
            <Helmet>
            <title>{DatosPropiedad.nombre}</title>
            <meta name="description" content={DatosPropiedad.descripcionCorta} />

            </Helmet>
            <div>
                <DeviceIdentifier isDesktop={true} isTablet={true}>
                    <div id="subPaginaArriendoFadeIn" >

			<div id = "contenedor_tarjeta">
				<div id="tarjeta">

					<div id="imagen_tarjeta">
						<img src ={DatosPropiedad.linkImagenPrincipal}/>
					</div>

					<div id = "informacion_tarjeta">
                                		<h1 id="titulo_propiedad_tarjeta">{DatosPropiedad.nombre}</h1>

						<div id="meta_propiedad_tarjeta">
							<div className="par_imagen_valor">
                                        			<img className="imagen_par" src="https://findicons.com/files/icons/2770/ios_7_icons/128/banknotes.png"/>
								<p className="valor_par">

									${nwc(DatosPropiedad.valor)}
								</p>
									
							</div>

						</div>
						<hr/>
						<div className="info_div">

                                        Disponibilidad: {DatosPropiedad.disponibilidad}
						</div>
					    {determinarSiHayEstac(DatosPropiedad)}
					    {determinarSiHayGCom(DatosPropiedad)}
						<div className="info_div">

                                        		Programa: {DatosPropiedad.descripcionLarga}
						</div>

						<div className="info_div">

                                        	Propiedad ubicada en {DatosPropiedad.ubicacionExtensa}.
						</div>
						<div id = "info_div_contenedor_botones">
							<button > <a style={{color:"white", textDecoration:"none"}} href = "/#/api/contacto" >Contacto</a>	</button>
							<button ><a style={{color:"white", textDecoration:"none"}}  href = "/#/api/faqs">  Preguntas Frecuentes</a>	</button>

						</div>


					</div>
				</div>
			</div>
			<hr id ="hr_divisor" />
			<div id = "contenedor_cuerpo_propiedad">
				<div id = "galeria_imagenes">
				

					{mostrarVideo(DatosPropiedad)}
					<h1>

					Galeria propiedad
					</h1>	

					<div className ="imagenes_propiedad">
						{mostrar_galeria_interior}





					</div>

                                        {determinarSiHayExterior(imagenesExterior)}
					<div className ="imagenes_propiedad">
                                        {mostrar_galeria_exterior}
					</div >
				</div>
				<div id="ubicacion_interes">
					<h1>

				Ubicacion	
					</h1>	
					
				    	<div id="contenedor_gmap" style={{"overflow":"hidden"}}>

						<Mapa direccion = {DatosPropiedad.ubicacion}/>
				    	</div>
					<h1>
						En la cercania:
					</h1>
					<ul id="lista_lugares_interes">
						{
						mostrarLugaresInteres
						}
					</ul>
				</div>
			
			</div>
			

                    </div>
                </DeviceIdentifier>
                <DeviceIdentifier  isMobile={true}>
                    <SubPaginaPropiedadMobile
                        DatosPropiedad={DatosPropiedad}
                        imagenesInterior={imagenesInterior}
                        imagenesExterior={imagenesExterior}
                        lugaresInteres={lugaresInteres}





                    >

                    </SubPaginaPropiedadMobile>
                </DeviceIdentifier >



            </div>
            </>
        )

    } else {
        return (
            <>

            <Helmet>
            <title>{DatosPropiedad.nombre}</title>
            <meta name="description" content={DatosPropiedad.descripcionCorta} />

            </Helmet>
              <Loading></Loading>
            </>
       )
    }








}
