import {useForm} from 'react-hook-form';
import {useLocation , useNavigate} from 'react-router-dom';


export default function LoginAdmin(){
    const location = useLocation();
    const navigate= useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit =async (data)=>{
        const formData= new FormData();
        formData.append("email", data.email);
        formData.append("password", data.password);
        const res=await fetch(location.pathname,{
            method:"POST",
            body:formData
        }).then(res=>res.json())
        .then((data)=>{
            sessionStorage.setItem("token", data.idToken);
            sessionStorage.setItem("user", data.idUser);
            navigate("/api/admin")
        }

        );

    }
    return(
        <div style={{margin:"30px"}}>
            <h2>Acceder</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label>Usuario:</label>
                        <input {...register("email", {required: true, max: 78})} name = "email" type="email" class="form-control"  ></input>
                    </div>
                    <div>
                        <label>Contraseña:</label>
                        <input {...register("password")} name ="password" type="password" class="form-control"  ></input>
                    </div>
                    
		    <button type="submit"  className="btn btn-primary" style = {{marginTop:"20px"}}>Acceder</button>
                </form>

        </div>


    )

}
