
import {Link, useLocation} from 'react-router-dom';

import {Container, Modal, Row, Col, Button, Carousel} from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Mapa from '../components/mapa.js';


export default function SubPaginaPropiedadMobile(props){
    function nwc(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const mostrarDormitorios = ()=>{
        if (props.DatosPropiedad.dormitorios ==1){
		if(props.DatosPropiedad.tipoPropiedad=="oficina"){

            return (
                <p>{props.DatosPropiedad.dormitorios} privado.</p>
            )
		}
            return (
                <p>{props.DatosPropiedad.dormitorios} dormitorio</p>
            )

        }else{

		if(props.DatosPropiedad.tipoPropiedad=="oficina"){
            return (
                <p>{props.DatosPropiedad.dormitorios} privados.</p>
            )
		}
            return (
                <p>{props.DatosPropiedad.dormitorios} dormitorios</p>

            )

        }

    }

    const mostrarBanos = () =>{
        if (props.DatosPropiedad.banos ==1){
            return (
                <p>{props.DatosPropiedad.banos} baño</p>
            )

        }else {
            return (
                <p>{props.DatosPropiedad.banos} baños</p>
            )

        }

    };
    const mostrarLugaresInteres = props.lugaresInteres.map((lugar, i)=>{
        
        return(
            <li key ={i} className="elementoListaLugares">{lugar}</li>
        );

    });


    const mostrarGridFotos = props.imagenesInterior.map((imagen, i )=> {
        if (imagen.length==3){
            return (        
                <div style={{margin:"0px", padding:"0px"}}>
                    <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                    <Zoom>
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[0]}></img>
                    </Zoom>                    
                    </Row >
                    <Row className = "subPaginaPropiedadMobileContainerFotoUnica"> 
                    <Zoom>                    
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[1]}></img>
                    </Zoom>                    
                    </Row >
                    <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                    <Zoom>                    
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[2]}></img>
                    </Zoom>                    
                    </Row>
                    
            </div>
            )
        } if (imagen.length==2){
            
            return (
            
            <Row >
                <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                <Zoom>                    
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[0]}></img>
                    </Zoom>                    
                </Row>
                <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[1]}></img>

                </Row>          
               

        </Row>

        )

        }if(imagen.length <2){
            return(
                <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                <Zoom>                    
                <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[0]}></img>
                </Zoom>                    
                </Row>
            )

        }
        
    });

    const mostrarGridFotosExterior = props.imagenesExterior.map((imagen, i )=> {
        if (imagen.length==3){
            return (        
                <div style={{margin:"0px", padding:"0px"}}>
                    <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                    <Zoom>
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[0]}></img>
                    </Zoom>                    
                    </Row >
                    <Row className = "subPaginaPropiedadMobileContainerFotoUnica"> 
                    <Zoom>                    
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[1]}></img>
                    </Zoom>                    
                    </Row >
                    <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                    <Zoom>                    
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[2]}></img>
                    </Zoom>                    
                    </Row>
                    
            </div>
            )
        } if (imagen.length==2){
            
            return (
            
            <Row >
                <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                <Zoom>                    
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[0]}></img>
                    </Zoom>                    
                </Row>
                <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                    <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[1]}></img>

                </Row>          
            
        </Row>
        )
        }if(imagen.length <2){
            return(
                <Row className = "subPaginaPropiedadMobileContainerFotoUnica">
                <Zoom>                    
                <img className = "subPaginaPropiedadMobileFotoUnica" src={imagen[0]}></img>
                </Zoom>                    
                </Row>
            )

        }
        
    });
    const mostrarVideo = (DatosPropiedad)=>{
        try{return(
            <div>
                <h2>Video</h2>
                <div className="subPaginaPropiedadContenedorVideo">

                    <iframe webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen  className="subPaginaPropiedadMobileVideo"  src={definirDireccionVideo(props.DatosPropiedad)+"?muted=1"}  ></iframe>

                </div>
                
                <script src="https://player.vimeo.com/api/player.js"></script>

            </div>
            
        )
        }catch (error){
            return (<></>);
        }

         
    }
    function determinarSiHayExterior(imagenesExterior){
        try{
        if (imagenesExterior[0].length !=0 ){
            return <h1>Galería exterior</h1>
        }else return <></>
        }catch (error){
            return <></>
        }
    }
    function determinarSiHayEstac(DatosPropiedad){
        try{

        
        if (DatosPropiedad.estacionamientos!=0 ){
            return <p style={{textAlign:"justify"}}>Estacionamientos: {DatosPropiedad.estacionamientos}</p>
        }else return <></>
        }catch(error){
            return <></>
        }

    }

    function determinarSiHayGCom(DatosPropiedad){
        try{if (DatosPropiedad.gastosComunes!=0 ){
            return <p style={{textAlign:"justify"}}>Gastos Comunes: ${nwc(DatosPropiedad.gastosComunes)}</p>
        }else return <></>
        }catch(error){return <></>}
    }



    function renderBanos(){
        if(props.DatosPropiedad.tipoPropiedad!=='estacionamiento'){
            return (

            <Row>
                        <Col xs = {3}>
                            {"  "}
                        </Col>
                        <Col xs = {1} style={{marginRight:"1rem"}}>
                            <img style={{width:"25px",height:"auto"}} src="https://cdn-icons-png.flaticon.com/512/456/456446.png"></img>
                        </Col>

                        <Col style={{textAlign:'left'}} >
                            {mostrarBanos(props.DatosPropiedad.banos)}
                        </Col>
            </Row>
            )
        }

    }
    function renderDormitorios(){

        if(props.DatosPropiedad.tipoPropiedad!=='estacionamiento'){
            return (

            <Row>
                        <Col xs = {3}>
                            {"  "}
                        </Col>
                        <Col xs={1} style={{marginRight:"1rem"}}>
                            <img style={{width:"25px", height: "auto"}} src="https://findicons.com/files/icons/2770/ios_7_icons/128/bed.png"></img>
                        </Col>

                        <Col style={{textAlign:'left'}}>
                            {mostrarDormitorios(props.DatosPropiedad.dormitorios)}
                        </Col>

            </Row>
            )
        }

    }
    function renderSuperficie(){

        if(props.DatosPropiedad.tipoPropiedad!=='estacionamiento'){
            return (

            <p style={{textAlign:"justify"}}>
              Superficie: {props.DatosPropiedad.superficie} (m<sup>2</sup>)

            </p>
            )
        }

    }





    const definirDireccionVideo = (DatosPropiedad)=>{
        const dv= DatosPropiedad.direccionVideo;
        if (dv.indexOf("os/")!=-1){
            let link = dv.substring(dv.indexOf("os/")+3, dv.length)
            return ("https://player.vimeo.com/video/"+link)
            
        }else return dv;
    }
    return(
        <div id = "subPaginaArriendoFadeIn" className = "subPaginaPropiedadMobileContenedor">
            <Row style={{margin:"0px", padding:"0px"}}>
            <h1 className="subPaginaPropiedadTitulo" >{props.DatosPropiedad.nombre}</h1>
                
            </Row>  
            <Row style={{margin:"0px", padding:"0px"}}>
              <img className="subPaginaArriendoImagenPerfil" src ={props.DatosPropiedad.linkImagenPrincipal}></img>

            </Row>    
            <Row style={{marginTop:"1rem"}}>
                        
                        <Col xs = {3}>
                            {"  "}
                        </Col>
                        <Col xs={1} style={{marginRight:"1rem"}}>
                            <img style={{width:"25px",height:"auto"}} src="https://findicons.com/files/icons/2770/ios_7_icons/128/banknotes.png"></img>
                    
                        </Col>
                        <Col style={{textAlign:'left'}}>
                          ${nwc(props.DatosPropiedad.valor)}
                        </Col>
                
            </Row>
          {renderDormitorios()}
          {renderBanos()}
            <Row className="subPaginaPropiedadMobileParrafo">
              {renderSuperficie()}
            <p style={{textAlign:"justify"}}>
                Disponibilidad: {props.DatosPropiedad.disponibilidad}

            </p>
            {determinarSiHayEstac(props.DatosPropiedad)}
            {determinarSiHayGCom(props.DatosPropiedad)}
        

            
            
            
            <p style={{textAlign:"justify"}}>
                            Programa: {props.DatosPropiedad.descripcionLarga}

            </p>

            
            <p style={{textAlign:"justify"}}>
                Propiedad ubicada en {props.DatosPropiedad.ubicacionExtensa}.
            </p>
            <div id="subPaginaPropiedadMobileContenedorBotonContacto">
            <Button id = "subPaginaPropiedadMobileBotonContacto">
                <Link style={{color:"white"}} to = "/api/contacto">Contacto</Link>
                
            </Button>
            
            
            <Button id="subPaginaPropiedadMobileBotonContacto">
                <Link style={{ color: "white" }} to="/api/faqs">Preguntas frecuentes</Link>

            </Button>
            

            

            </div>
                        

            </Row>

           <Row style={{margin:"0px", padding:"0px"}}>
                    {mostrarVideo(props.DatosPropiedad)}
                    
                   
                    
           </Row>
           <Row style={{margin:"0px", padding:"0px"}}>
            <h2 className="subPaginaPropiedadTitulo">Galería propiedad</h2>

                    <Row style={{margin:"0px", padding:"0px"}}>
                        
                        {mostrarGridFotos}   
                        
                    </Row>
                    <Row style={{margin:"0px", padding:"0px"}}>
                        
                        
                        {determinarSiHayExterior(props.imagenesExterior)}
                        {mostrarGridFotosExterior}   
                    </Row>
            </Row>
           <Row>
           <h2 className="subPaginaPropiedadTitulo">Ubicación</h2>
                        <div id="contenedorMapaSubProp" style={{"overflow":"hidden"}}>
                          <Mapa direccion = {props.DatosPropiedad.ubicacion}/>
                        </div>
           </Row>
           <Row>
                <h2 className="subPaginaPropiedadTitulo">Información de la zona</h2>
                <ul style={{textAlign:"left",marginLeft:"2rem"}}>
                    {mostrarLugaresInteres}

                </ul>
            </Row>
            
           

            <hr></hr>
            

            </div>

    )




}
