import {Carousel, Col, Row} from 'react-bootstrap';
import { useState, useEffect, setState, useRef} from 'react';
import { useLocation} from 'react-router-dom';
import AvisoPropiedad from '../components/avisopropiedad.js';
import { useForm } from "react-hook-form";
import DeviceIdentifier from 'react-device-identifier';
import MostrarDisponibleMobile from '../mobilePages/mostrarDisponibleMobile.js';
import Loading from '../components/loading.js';
import NoDisponible from '../components/notAvailable.js';
import { Helmet } from "react-helmet-async";

function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}
export default function MostrarDisponibles(props){
  const [datos, setDatos] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { register, handleSubmit} = useForm();
  let location = useLocation();

  const isMounted = useIsMounted();
  const onSubmit = async (data) => {

        const formData = new FormData();
        
        formData.append("comuna", data.comuna);
        const res = await fetch(location.pathname, {
            method: "POST", 
            body:formData
        }).then(res => res.json())
        
        setComunas(res.comunas);
        setDatos(res.datos);
    };
   

  
  useEffect(() => {
	    fetch(location.pathname)
	      .then(res => res.json())
	      .then(
		(result) => {
			if(isMounted.current){
				  setDatos(result.datos);
				  setComunas(result.comunas);
				  setIsLoaded(true);
			}
		}
	      )
  }, [])


  const mostrarPropiedades = datos.map((dato, i ) =>{
    return (
        <AvisoPropiedad
            nombre = {dato.nombre}
            imagenPrincipal ={dato.linkImagenPrincipal}
            precio = {dato.valor}
            superficie ={dato.superficie}
            dormitorios = {dato.dormitorios}
            banos = {dato.banos}
            direccion ={dato.ubicacionExtensa}
	    tipoPropiedad={dato.tipoPropiedad}
            key  ={i}       
        
        >

        </AvisoPropiedad>
    )


  })
  const mostrarComunas = comunas.map((comuna,i)=>{
    return(
      <option value={comuna} key = {i}>{comuna}</option>


    )


  })



  if (isLoaded){

    if(datos.length ==0){
      return(
          <>
            <Helmet>
            <title>Propiedades disponibles</title>
            <meta name="description" content="Propiedades disponibles" />

            </Helmet>

          <NoDisponible></NoDisponible>
          </>)

    }else{

    return (
      <>

            <Helmet>
            <title>Propiedades disponibles</title>
            <meta name="description" content="Propiedades disponibles" />

            </Helmet>
      <div>
          <DeviceIdentifier isTablet={true} isDesktop={true}>
		<div>

              <h1 className = "tituloMostrarPropiedades"  >{props.propiedad} en {props.tipo}</h1>
		    <div id="mostrar_disponibles_container">
			<div id="mostrar_disponibles_filtrado">
				  <h3 id ="tituloFiltrarComuna">Filtrar por Comuna</h3>
				  <form onSubmit={handleSubmit(onSubmit)} style={{margin:"1rem"}}>
				      
				      <div>
					  <select {...register('comuna')} name = "comuna" className="formaFiltrado" >
					      <option defaultValue="" disabled defaultValue>Seleccionar</option>
					      {mostrarComunas}
					  </select>
				      </div>
				      <div className = "contenedorBotonListaPropiedades">
					<button style = {{marginTop:"1rem"}} className="botonFiltrar" >Filtrar</button>
				      </div>  
				  </form>
			</div>
			<div id="mostrar_disponibles_avisos">
                  		{mostrarPropiedades}
			</div>
		<hr style={{"margin":"0"}}/>



                 </div>
              </div>
          </DeviceIdentifier>
          <DeviceIdentifier  isMobile={true}>
            <MostrarDisponibleMobile
              propiedad = {props.propiedad}
              tipo ={props.tipo}
              comunas = {comunas}
              datos ={datos}
	      setComunas = {setComunas}
              setDatos ={setDatos}
            ></MostrarDisponibleMobile>
          </DeviceIdentifier>

        </div>
      </>
    )}

  }else{
    return(
        <Loading></Loading>

    )
  }


  



}
