import {Col, Row,Container, Accordion, Collapse, Button} from 'react-bootstrap';
import {useState, useEffect} from 'react';

import {Link, useLocation} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Blocked from '../components/blocked.js';



export default function AdminEditarPagina(){
    let location = useLocation();
    const [isLoaded, setIsLoaded] =useState("");
    const [datos, setDatos] = useState([]);
    const [lugaresInteres, setLugaresInteres] = useState([0]);

    const { register, handleSubmit} = useForm();
    const onSubmit = async (data) => {
        const formData = new FormData();
        
        
        /*
        for ( var i = 0 ; i < data.imagenPrincipal.length; i+=1){
            formData.append("imagenPrincipal", data.imagenPrincipal[i]);
        }
        for ( var i = 0 ; i < data.imagenesInterior.length; i+=1){
            formData.append("imagenesInterior", data.imagenesInterior[i]);
        }
        for ( var i = 0 ; i < data.imagenesExterior.length; i+=1){
            formData.append("imagenesExterior", data.imagenesExterior[i]);
        }
        for ( var i = 0 ; i < data.video.length; i+=1){
            formData.append("video", data.video[i]);
        }*/
        for (var i= 0; i< lugaresInteres.length; i+=1){
            formData.append("lugarInteres"+i, data["lugarInteres"+i]);
        }
        

        
        formData.append("propiedadSeleccionada", data["propiedadSeleccionada"]);
        formData.append("valor", data.valor);
        formData.append("superficie", data.superficie);
        formData.append("gastosComunes", data.gastosComunes);
        formData.append("banos", data.banos);
        formData.append("dormitorios", data.dormitorios);
        formData.append("comuna", data.comuna);
        formData.append("disponibilidad", data.disponibilidad);
        formData.append("estacionamientos", data.estacionamientos);
        formData.append("ubicacion", data.ubicacion);
        formData.append("ubicacionExtensa", data.ubicacionExtensa);

        formData.append("venta", data.venta);
        formData.append("arriendo", data.arriendo);
        formData.append("disponible", data.disponible);
        formData.append("descripcionCorta", data.descripcionCorta);
        formData.append("descripcionLarga", data.descripcionLarga);

        formData.append("tipoPropiedad", data.tipoPropiedad);

        const res = await fetch(location.pathname, {
            method: "POST", 
            headers:{
                "authorization": sessionStorage.getItem("token"),
                    "provider":"anonymous",
                    "uid":sessionStorage.getItem("user")

            },
            body:formData
        }).then(res => res.json())
    };
    
    useEffect(() => {
        fetch(location.pathname, {
            method:"GET", 
            headers:{
                "authorization": sessionStorage.getItem("token")
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              if (result.message =="acceso restringido" || result.message =="token invalida"){
                  setIsLoaded("false")
              }
              if (result.message=="legal"){
                  setIsLoaded("true");
                  setDatos(result.propiedades);
              }

            }
          )
      }, [])


    const mostrarPropiedadesAEditar= datos.map((dato,i)=>{
        return(
            <option value = {dato.id}>{dato.nombre}</option>
        )
    })


    const mostrarInputLugaresInteres =  lugaresInteres.map((lugar, i)=>{
        return(
            <input {...register("lugarInteres"+i)} key = {i}>
            </input>
        )

    })

   
     function agregarInput(){
        setLugaresInteres([...lugaresInteres, lugaresInteres[lugaresInteres.legth]+1]);

    }



    if (isLoaded=="false"){
        return <Blocked></Blocked>
    }
    if (isLoaded=="true"){   


    return(
        <div style={{margin:"10px"}}>
            <h1>Editar pagina</h1>
            <p>Para editar una página, selecciona la propiedad a editar, y luego ingresa datos <b>unicamente</b> los campos que quieres actualizar.</p>
            <form  onSubmit={handleSubmit(onSubmit)}>
                <label>Selecciona la propiedad</label>
                <select {...register('propiedadSeleccionada')}   name = 'propiedadSeleccionada' class="form-control" id="exampleFormControlSelect1">
                {mostrarPropiedadesAEditar}
                </select>
                <h2>Contenido a editar</h2>
                
                <div>                    
                    

                    <label>tipo de propiedad</label>

                    <select {...register('tipoPropiedad')} name = "tipopropiedad" className="form-control">
                                               
                        <option value=""></option>
                        <option value="casa">casa</option>
                        
                        <option value="departamento">departamento</option>
                        <option value="estacionamiento">estacionamiento</option> 
                        <option value="oficina">oficina</option>                       

                    </select>
                </div>
                <div>
                    <label>Valor Propiedad:</label>
                    <input {...register('valor')}  name = "valor" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Superficie Propiedad:</label>
                    <input {...register('superficie')}  name = "superficie" type="text" className="form-control"  ></input>
                </div>
                
                <div>
                    <label>Gastos Comunes:</label>
                    <input {...register('gastosComunes')}  name = "gastosComunes" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Baños:</label>
                    <input {...register('banos')}  name = "banos" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Dormitorios:</label>
                    <input {...register('dormitorios')}  name = "dormitorios" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Comuna</label>
                    <input {...register('comuna')}  name = "comuna"  type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Disponibilidad:</label>
                    <input {...register('disponibilidad')}  name = "disponibilidad" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Estacionamientos:</label>
                    <input {...register('estacionamientos')}  name = "estacionamientos" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Ubicación (descripción extensa):</label>
                    <input {...register('ubicacionExtensa')}  name = "ubicacionExtensa" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>Ubicación (solo la calle de la propiedad, lo demás es automático)</label>
                    <input {...register('ubicacion')} name = "ubicacion" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>¿En venta?</label>
                    <select {...register('venta')}  name = "venta" className="form-control">

                        <option value=""></option>
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label>¿En arriendo?</label>
                    
                    <select {...register('arriendo')} name = "arriendo" className="form-control">
                        <option value=""></option>
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
                
                <div>
                    <label>¿Disponible?</label>
                    <select {...register('disponible')} name = "disponible" className="form-control">
			<option value=""> </option>
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                    </select>
                </div>
                {
                    /*
                    
                     <div className="form-group">
                    <label >imagen principal</label><br></br>
                    <input {...register('imagenPrincipal')} type="file" class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>imágenes interior</label><br></br>
                <input multiple type = "file" {...register('imagenesInterior')} type="file" class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>imágenes exterior</label><br></br>
                <input multiple type = "file" {...register('imagenesExterior')}class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>video</label><br></br>
                <input  type = "file" {...register('video')} class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                    
                    
                    */
                }
               
               
                <div className="form-group">
                    <label>Lugares de interés</label><br></br>
                    {mostrarInputLugaresInteres}
                    <button   className="btn btn-primary" type="button" onClick={agregarInput} >Agregar lugar de interés</button>

                </div>
                
                <div>
                    <label >Descripción (corta, ideal dos lineas.):</label>
                    <textarea {...register('descripcionCorta')}  name = "descripcionCorta" className="form-control" rows="3"></textarea>
                </div>
                <div>
                    <label >Descripción (larga):</label>
                    <textarea {...register('descripcionLarga')} name = "descripcionLarga" className="form-control" rows="3"></textarea>
                </div>
                
                <button className="btn btn-primary" style = {{marginTop:"20px"}}>Actualizar datos</button>
            </form>

        </div>
    )
    }else {
        return <h1>Loading...</h1>
    }
}
