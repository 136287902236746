import { Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

export default function Faqs(){

    let location = useLocation();

    return(
        <>
            <Helmet>
            <title>Preguntas Frecuentes</title>
            <meta name="description" content="preguntas frecuentes" />

            </Helmet>

    <div className="faqsContenedorPrincipal">
            <h1>Preguntas frecuentes y requisitos</h1>
            De forma general documentos para arrendar casa como trabajador dependiente son:
            <ul>
                <li>
                Documento de identidad vigente.
                </li>
                <li>
                Contrato de trabajo vigente e indefinido.
                </li>
                <li>
                Certificado de antigüedad laboral. 
                </li>
                <li>
                Liquidaciones de sueldo (últimas seis o doce).  
                </li>
                <li>
                Cotizaciones previsionales últimos seis meses.
                </li>
                <li>
                Certificado de Dicom e Informe Deuda Suprintendencia de Bancos.
                </li>
                
            </ul>
            <p>
            Los requisitos laborales acreditan la remuneración y la estabilidad económica. Deben contener datos de la empresa, firma y sello acreditable. 
            </p>
            <p>
            Por otra parte, el certificado de Dicom se obtiene a través de Equifax (se solicita el Platinum Plus 360 , el que se limita al Informe de Arriendo no servirá). 
            </p>
            <p>
            Para un trabajador independientes  o emprendedor deben reemplazar los documentos laborales por:
            </p>
            <ul>
                <li>
                Boletas de honorarios (últimas seis o doce).
                </li>
                <li>
                Declaración de pago de impuestos mensual.
                </li>

                <li>
                Declaración de impuesto sobre la renta.
                    </li>

                    <li>
                    Ambas declaraciones se  obtienen en página del Servicio de Impuestos Internos . La Declaración Mensual (F29) corresponde al IVA, mientras que la Declaración Impuesto Sobre la Renta (F22) . En ambos caos se requiere formulario compacto y certificado solemne que valide dicha declaración .-
                    </li>

                    <li>
                    Certificado de Dicom e Informe Deuda Suprintendencia de Bancos.
                    </li>
                    

                
            </ul>
            <p>
            NOTA 1 : El presente listado  para todo caso no es taxativo, sino enumeración general sujeta a evaluación, que podría determinar la petición de otros documentos si fuere necesario .
            </p>
               
            <p>
            NOTA 2 : Para todo caso el interesado, una vez que confirme su real interés en el arriendo del inmueble, deberá presentar codeudor solidario, con requisitios similares de Renta, Información Comercial y residencia dentro de la Región Metropolitana, cuyo detalle se representará la efectuar reserva escrita de la propiedad.
            </p>

        </div>


        </>

    )


}
