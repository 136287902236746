
import { useLocation} from 'react-router-dom';
import AvisoPropiedad from '../components/avisopropiedad.js';
import {Carousel, Col, Row} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import NoDisponible from '../components/notAvailable.js';

export default function MostrarDisponibleMobile(props){

    let location = useLocation();
    const { register, handleSubmit} = useForm();



    const onSubmit = async (data) => {
        const formData = new FormData();
        
        formData.append("comuna", data.comuna);

        const res = await fetch(location.pathname, {
            method: "POST", 
            body:formData
        }).then(res => res.json())
	props.setComunas(res.comunas);
	props.setDatos(res.datos);
    };
   
    const mostrarPropiedades = props.datos.map((dato, i ) =>{
        return (
            <AvisoPropiedad
                nombre = {dato.nombre}
                imagenPrincipal ={dato.linkImagenPrincipal}
                precio = {dato.valor}
                superficie ={dato.superficie}
                dormitorios = {dato.dormitorios}
                banos = {dato.banos}
                direccion ={dato.ubicacionExtensa}
                key  ={i}       
            
            >
    
            </AvisoPropiedad>
        )
    })
    const mostrarComunas = props.comunas.map((comuna,i)=>{
        return(
          <option value={comuna} key = {i}>{comuna}</option>
    
    
        )
    
    
      })
    if(props.datos.length ==0){
        return(
            <NoDisponible></NoDisponible>
        )

    }else 
    
    {return(
        <Row style={{paddingRight:"0px"}}>
        <h1 style={{margin:"1rem"}}  >{props.propiedad} en {props.tipo}</h1>
        <Row style={{paddingRight:"0px"}}>
        
            <h5 style = {{margin:"1rem"}}>Filtrar por Comuna</h5>
            <form onSubmit={handleSubmit(onSubmit)} style={{margin:"1rem"}}>
                
                <div>
                    
                    <select {...register('comuna')} name = "comuna" className="form-control">
                        <option value="" disabled defaultValue>Seleccionar</option>
                        {mostrarComunas}
                    </select>
                </div>
  
                  
                <div className = "contenedorBotonListaPropiedades">
                  <input type="submit" style = {{marginTop:"1rem"}} className="btn btn-primary"value="Filtrar" ></input>
                </div>  
              

                
                
                
            </form>


        </Row>
        <Row style={{paddingRight:"0px"}}>
        <div >
            {mostrarPropiedades}
        </div>
  
        </Row>
        </Row>

    )


}}
