import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import App2 from './App2';
import reportWebVitals from './reportWebVitals';

import {HashRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Navegacion from './components/navegacion.js';
import Contacto from './pages/contacto.js';
import LoginAdmin from './pages/loginAdmin.js';
import Admin from './pages/admin.js';
import AdminEliminarPagina from './pages/adminEliminarPagina.js';
import AdminAgregarPagina from './pages/adminAgregarPagina.js';
import AdminEditarPagina from './pages/adminEditarPagina.js';
import SubPaginaPropiedad from './pages/subPaginaPropiedad.js';
import SubPaginaPropiedad2 from './pages/subPaginaPropiedad2.js';
import MostrarDisponibles from './pages/mostrarDisponibles.js';
//import Footer from './components/footer.js';
import Faqs from './pages/Faqs.js';
import { HelmetProvider } from "react-helmet-async";


ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
    <Router>
      <Navegacion></Navegacion>    
      <Routes>  
        <Route path='/api/arriendos/casas' element = {<MostrarDisponibles
            tipo = "arriendo"
            propiedad = "Casas"
		key = '/api/arriendos/casas'
          />}>
          
        </Route>
        <Route path='/api/arriendos/departamentos' element = {<MostrarDisponibles
            tipo = "arriendo"
            propiedad = "Departamentos"
		key = '/api/arriendos/departamentos'
          />}> 
          
        </Route>
        <Route path='/api/arriendos/estacionamientos' element = {<MostrarDisponibles
            tipo = "arriendo"
            propiedad = "Estacionamientos"
          />}>

        </Route>
        <Route path='/api/arriendos/oficinas' element = {            <MostrarDisponibles
            tipo = "arriendo"
            propiedad = "Oficinas"
		key = '/api/arriendos/oficinas'
          />}>

        </Route>
       <Route path = "/api/arriendos/:name" element ={<SubPaginaPropiedad2/>}>
          
        </Route>


        
        <Route path='/api/ventas/casas' element = {  <MostrarDisponibles
            tipo = "venta"
            propiedad = "Casa"
		key = '/api/ventas/casas'
          />}>
          
        </Route>
        <Route path='/api/ventas/departamentos' element= {            <MostrarDisponibles
            tipo = "venta"
            propiedad = "Departamentos"
		key = '/api/ventas/departamentos'
          />}>
        </Route>
        <Route path='/api/ventas/estacionamientos' element ={    <MostrarDisponibles
            tipo = "venta"
            propiedad = "Estacionamientos"
		key  = '/api/ventas/estacionamientos'
          />}>
        </Route>
        <Route path='/api/ventas/oficinas' element = {   <MostrarDisponibles
            tipo = "venta"
            propiedad = "Oficinas"
		key = '/api/ventas/oficinas'
          />}>
        </Route>

        <Route path='/api/faqs' element = {<Faqs/>}>
        </Route>
        <Route path='/api/contacto'element ={  <Contacto/>} >
        </Route>
        <Route path='/api/loginAdmin' element = {<LoginAdmin/>}>
        </Route>
       
        <Route path='/api/admin/editarPagina' element = { <AdminEditarPagina/>}>
        </Route>
        <Route path='/api/admin/eliminarPagina' element = { <AdminEliminarPagina/>}>
        </Route>
        <Route path='/api/admin/agregarPagina' element = {<AdminAgregarPagina/>}>
        </Route>
        
        
        <Route path ='/api/admin' element = {<Admin/>}>

        </Route>
        
        <Route path='/api/index' element = {

          <App2 />
}>
        </Route>
        <Route path='/api' element = {

          <App2 />
}>
        </Route>
        <Route  path='/' element={<Navigate to="/api/index" replace/>}/>
        <Route  path='*' element={<Navigate to="/api/index" replace/>}/>
        

      </Routes>
      
      {//<Footer></Footer>
      }

    </Router>

    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
