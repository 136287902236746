


export default function NoDisponible(){


    return(
        <div className="contenedorNoDisponible">
        <h3 >
            El tipo de propiedad que usted está buscando no está disponible...
        </h3>
        </div>
    )

}