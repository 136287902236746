



export default function Blocked()
{


return(
    <div style={{textAlign:'center'}}>
        <img src="https://m.media-amazon.com/images/I/51HhubOMQOL._AC_SL1024_.jpg"></img>


    </div>

)
}