import { Col, Row, Button } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";

import DeviceIdentifier from 'react-device-identifier';


export default function AvisoPropiedad(props){
    function nwc(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const history = useNavigate();
    const entrar = ()=>{
        history.push("/api/arriendos/"+props.nombre);
    }
    function dormopriv(){
	if(props.tipoPropiedad=="oficina"){
		return "privados"
	}
	return "dormitorios"
	}



    return(
        <div >
            <DeviceIdentifier isDesktop={true} isTablet={true}>
        <Link to={"/api/arriendos/"+props.nombre} style={{"textDecoration":"none"}}>

		<div className="aviso_propiedad_container">
			<div className="aviso_propiedad_imagen_container">

                   	     <img className="avisoPropiedadImagenAviso" src={props.imagenPrincipal}></img>
			</div>

			<div className="aviso_propiedad_info_container">

				    <p style={{fontSize:"20px" , fontFamily: "serif"}}>
					{props.nombre}
				    </p>

				    <p style={{fontSize:"30px", color:"black", fontFamily: "serif"}}>
				      ${nwc(props.precio)}
				    </p>
				
				    <p style={{fontSize:"15px"}}>
				      {props.superficie} (m<sup>2</sup>) |{props.dormitorios} {dormopriv()} | {props.banos} baños
			    
				    </p>
				    <div className="avisoPropiedadContenedorDireccion">

					<img style={{width:"35px", height:"auto"}} src="https://openmoji.org/data/black/svg/E0A9.svg"></img>
					{props.direccion}
				    </div>
		       </div>
		</div>

                       <hr/>


            </Link>
            </DeviceIdentifier>
            <DeviceIdentifier   isMobile={true} style={{paddingRight:"0px"}}>

        <Link to={"/api/arriendos/"+props.nombre} style={{"textDecoration":"none"}}>
              <Row id = "textoAzul" style={{padding:"0px", margin: ".5rem"}}>

                    <Row className = "avisoPropiedadMobileContenedorImagenAviso">
                        <img className="avisoPropiedadMobileImagenAviso" src={props.imagenPrincipal}></img>
                    </Row>
                    <Row>
                    <p style={{fontSize:"20px" , marginBottom:"0px", marginRight:"0px"}}>
                        {props.nombre}
                    </p>
                    <p style={{fontSize:"30px", marginBottom:"0px", color:"black", paddingRight:"0px"}}>
                      ${nwc(props.precio)}
                    </p>
                
                    <p style={{fontSize:"15px", marginTop:"0px" , paddingRight:"0px"}}>
                      {props.superficie} (m<sup>2</sup>) |{props.dormitorios} dormitorios | {props.banos} baños
            
                    </p>
                      <span style={{fontSize:"15px"}}>

                    <img style={{width:"35px", height:"auto"}} src="https://openmoji.org/data/black/svg/E0A9.svg"></img>{props.direccion}
                    </span>
                    </Row>
                    <Row className = "avisoPropiedadMobileContenedorBotonAviso" >
                        <Button><Link style={{color:"white"}} to={"/api/arriendos/"+props.nombre}>Ver más</Link></Button>
                    </Row>
                    <hr></hr>
                </Row>
            </Link>
            </DeviceIdentifier>

        </div>
    )



}
