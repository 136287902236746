
import ReactLoading from "react-loading";


export default function Loading(){


    return(
        <div className="containerCargando">
        <ReactLoading type={"bars"}  style={{height:"30%", width:"20%", color:"red"}} />
        </div>
    )
}