import {Container,Row, Col} from 'react-bootstrap';
import ContactoMobile from '../mobilePages/contactoMobile';
import { useForm } from "react-hook-form";
import DeviceIdentifier from 'react-device-identifier';
import { useLocation } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha"
import {useState} from 'react';
import { Helmet } from "react-helmet-async";



export default function Contacto(){
    const [isHuman, setIsHuman] = useState(false);
    const location = useLocation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    async function onCapChange( value) {
	let bod = {llave:value}
	 let status =await fetch(location.pathname+"/captcha",{
            method:"POST",
	    headers: {
      'Content-Type': 'application/json'
    		},
            body:JSON.stringify(bod)
        } )
	.then(res => res.json()) 
	.then(res =>{
		if(res["success"] == true){
			setIsHuman(true)

		}else{
			setIsHuman(false)
		}	
	})
   }
     const mostrarSubmit = (isHuman)=>{
	if(isHuman){
		return(
		<input className="btn btn-primary buttonForma" value="enviar"type='submit' />	
		)
	}else{
		return(
		<input className="btn btn-primary buttonForma" value="enviar"type='submit' disabled />	
		
		)	
	}

}

    const onSubmit = async (data)=>{
        const formData= new FormData();
        formData.append("nombre", data.nombre);
        formData.append("email", data.email);
        formData.append("propiedadInteres", data.propiedadInteres);
        formData.append("telefono", data.telefono);
        formData.append("mensaje", data.mensaje);
        const res = await fetch(location.pathname,{
            method:"POST",
            body:formData
        } ).then(
            res =>res.json()
        ).then(res=>{
            alert(res.status)
        }
        )

    }
    return(
        <div>
            <Helmet>
              <title>Contacto</title>
            <meta name="description" content="contáctanos mediante esta página o envíanos un correo. Llamadas solo en horarios indicados." />

            </Helmet>
            <DeviceIdentifier isDesktop={true}>
                <Container>
                    <Container id = "contenedorAbout">
                    
                    <Row>
                        <Col xs={7}>
                            <Row></Row>
                            
                            <h1>Sobre nosotros</h1>
                            <p>Desde 1991 prestando servicios profesionales  en gestión inmobiliaria en ámbito profesional, para la adquisición de inmuebles , gestión integral de su  arriendo   y  administración de su propiedad,  como también su colocación en venta del mismo , respaldado en  la calidad de Abogado de la Universidad Católica de Chile,  pos título en UTEM en tributario, y Tasador de la escuela de Arquitectura de la Universidad de Chile, me permite minimizar sus  riesgos desde la  valoración de inmuebles para venta y arriendo, y generar todo gestión necesaria para su tranquilidad.</p>
                            <Row>
                            
                        <h2>Contacto</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <input className="inputForma" type="text" placeholder="nombre" {...register("nombre")} required />
                        
                        
                        <input  className="inputForma" type="email" placeholder="correo (nos contactaremos con usted por este medio)" {...register("email")} required />
                       
                        <input  className="inputForma" type="text" placeholder="propiedad que me interesa" {...register("propiedadInteres")} />
                        
                        <input  className="inputForma" type="text" placeholder="telefono" {...register("telefono")} required />
                        
                        <textarea  className="inputForma" placeholder="mensaje" {...register("mensaje" )} required />
			<div className="recaptchaContainer" >
<ReCAPTCHA  sitekey="6LfxFg8eAAAAAOSFD2W-AckjaWEpypzBPzxQjm_H" onChange={onCapChange} />

			</div>
                       	<div>
		{
			mostrarSubmit(isHuman)
		}

		</div>
		                        </form>
                           
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                            <div id="gmap_canvas" style={{"overflow":"hidden"}}>
                         
                               <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=padre%20mariano%20210%20providencia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" ></iframe>
                              
                            </div>

                            </Row>
                            <Row style={{padding:'3px'}}>
                                
                            Dirección: Padre Mariano 210, Oficina 408, Providencia, Región Metropolitana.<br></br>
                            Correo Contacto: oyarzun.propiedades@yahoo.com<br></br>
                            Teléfonos:<br></br>
                            +223418815<br></br>
                            +222443634<br></br>
                            Horarios de atención: <br></br>
                            Lunes a viernes de 9:30 a 14:00 y de 15:00 a 18:00.<br></br>
                            </Row>
                            
                        </Col>

                    </Row>
                    
                    
                    </Container>



                </Container>
            </DeviceIdentifier>
            <DeviceIdentifier isTablet={true} isMobile={true}>
                <ContactoMobile>
                    
                </ContactoMobile>

            </DeviceIdentifier>

        </div>
    )

}
