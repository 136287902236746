
import {Container, Button } from 'react-bootstrap';
import {useLocation, Link,useNavigate} from 'react-router-dom'
import {useEffect, useState}  from 'react'
import Blocked from '../components/blocked.js';


export default function Admin(){

    const [isLoaded, setIsLoaded] = useState("")
    const location = useLocation();
    const navigate= useNavigate();

    useEffect(() => {
        window.addEventListener("beforeunload", (ev) => 
        {  
            
            fetch("http://localhost:5000/api/logout", {
                method:"POST", 
                headers:{
                    "authorization": sessionStorage.getItem("token"),
                    "provider":"anonymous",
                    "uid":sessionStorage.getItem("user")
                }
            })
            
        });
      fetch(location.pathname, {
          method:"GET", 
          headers:{
              	"authorization": sessionStorage.getItem("token"), 	
          }
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result.message =="acceso restringido" || result.message =="token invalida"){
                setIsLoaded("false")
            }
            if (result.message=="legal"){
                setIsLoaded("true")
            }



          }
        )
    }, [])


    const cerrarSesion = () => {
        const res = fetch("/api/logout", {
            method:"POST", 
            headers:{
                "authorization": sessionStorage.getItem("token"),
		"uid":sessionStorage.getItem("user")
            }
        }).then((res)=>res.json())
	  .then((result)=>{
	    if(result.estado=="ok"){
            	sessionStorage.removeItem("token");
            	navigate("/");
		}else{
			alert("algo ha salido mal");
		}
       }
 );
}


    if (isLoaded=="false"){
        return <Blocked></Blocked>
    }else if (isLoaded=="true"){
        

    return(
        <Container>
            <h1>Administrar página web</h1>
            <Button onClick={cerrarSesion}>Cerrar sesión</Button>
            
            <h3>Agregar propiedad</h3>
            <Button><Link style={{color:"white"}} to="/api/admin/agregarPagina">Llevame alli</Link></Button>
            <h3>Editar propiedad</h3>
            <Button><Link style={{color:"white"}} to="/api/admin/editarPagina">Llevame alli</Link></Button>
            <h3>Eliminar propiedad</h3>
            <Button><Link style={{color:"white"}} to="/api/admin/eliminarPagina">Llevame alli</Link></Button>
            
            
            



        </Container>


    )
    }else{
        return <h1>Loading...</h1>
    }



}
