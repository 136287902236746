import {Navbar, Nav, NavDropdown, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import DeviceIdentifier from 'react-device-identifier';


export default function Navegacion (){
    return (
    <div>
        <DeviceIdentifier isTablet={true} isDesktop={true}>
		<Navbar style={{"backgroundColor":"black"}} id = "barra_superior" >
		    <Row style={{width:"100%"}}>
			<Col className = "navegacionColumna">
			<a href="tel: +56 2 2341 8815">+56 2 2341 8815</a>
			</Col>
			<Col className = "navegacionColumna">
			  <a href ="mailto: oyarzun.propiedades@yahoo.com">oyarzun.propiedades@yahoo.com</a>
			</Col>
			<Col className = "navegacionColumna">Lun - Vie: 9:30 - 18:00</Col>
		    </Row>
		</Navbar>
		<Navbar id = "barra_inferior"  expand="lg" className="float-right" >
		    
		    <Navbar id ="texto_main"  >
				<a href="/#/api/index">
				César Oyarzún Propiedades
			</a>

			</Navbar>
		    <Navbar.Toggle aria-controls="basic-navbar-nav" />
		    <Navbar.Collapse id="basic-navbar-nav " >
			<Nav className="ms-auto">
			   
			    <Nav.Link className="linkSimple" href='/#/api/index'>
					Inicio
			    </Nav.Link>
			    <Nav.Link className="linkSimple" href='/#/api/contacto'>
					Contacto y Sobre nosotros
			    </Nav.Link>
			    <NavDropdown title="Arriendos"  id="basic-nav-dropdown">
			      <NavDropdown.Item  className= "ddown_item"  href='/#/api/arriendos/casas'>
					Casas
			      </NavDropdown.Item>
			      <NavDropdown.Item className= "ddown_item"  href = "/#/api/arriendos/departamentos">
				Departamentos
				</NavDropdown.Item>
			      <NavDropdown.Item className= "ddown_item" href='/#/api/arriendos/oficinas'>
					Oficinas
				</NavDropdown.Item>
			      <NavDropdown.Item className= "ddown_item" href='/#/api/arriendos/estacionamientos' >
					Estacionamientos
				</NavDropdown.Item>
			    </NavDropdown>
			   
			    <NavDropdown title="Ventas"  id="basic-nav-dropdown">
			      <NavDropdown.Item className= "ddown_item" href='/#/api/ventas/casas'>

					Casas

			      </NavDropdown.Item>
			      <NavDropdown.Item className= "ddown_item" href='/#/api/ventas/departamentos'>

						Departamentos
				</NavDropdown.Item>
			      <NavDropdown.Item  className= "ddown_item"  href='/#/api/ventas/oficinas'>
						Oficinas
				</NavDropdown.Item>
			      <NavDropdown.Item className= "ddown_item"  href='/#/api/ventas/oficinas'>

						Estacionamientos
				</NavDropdown.Item>
			    </NavDropdown>

			</Nav>
		    
		    </Navbar.Collapse>
		</Navbar>
        </DeviceIdentifier>
        <DeviceIdentifier  isMobile={true}>
	       
		
		<Navbar id = "textoNavegacion"  expand="lg" className="float-right">
		    
		    <Navbar.Brand><Link to = {"/api/index"}style={{textDecoration:"none"}} >César Oyarzún Propiedades</Link></Navbar.Brand>
		    <Navbar.Toggle aria-controls="basic-navbar-nav" />
		    <Navbar.Collapse id="basic-navbar-nav " >
			<Nav style={{textAlign:'center'}} className="ms-auto">
			   
				<div className="NavegacionMobileContenedorFilasDescriptivas">
				    <div className="NavegacionMobileFilaMobile">+56 2 2341 8815</div>
				    
				    <div className="NavegacionMobileFilaMobile">Lun - Vie: 9:30 - 18:00</div>
				</div>
			    <Nav.Link >
				<Link style={{textDecoration:"none"}} to='/api/index'>Inicio</Link>
			    </Nav.Link>

			    <NavDropdown title="Arriendos"  id="basic-nav-dropdown">
			      <NavDropdown.Item  style={{marginTop:".7rem"}}className= "ddown_item"  href='/#/api/arriendos/casas'>
					Casas
			      </NavDropdown.Item>
			      <NavDropdown.Item style={{marginTop:".7rem"}}className= "ddown_item"  href = "/#/api/arriendos/departamentos">
				Departamentos
				</NavDropdown.Item>
			      <NavDropdown.Item style={{marginTop:".7rem"}}className= "ddown_item" href='/#/api/arriendos/oficinas'>
					Oficinas
				</NavDropdown.Item>
			      <NavDropdown.Item style={{marginTop:".7rem"}}className= "ddown_item" href='/#/api/arriendos/estacionamientos' >
					Estacionamientos
				</NavDropdown.Item>
			    </NavDropdown>
			   
			    <NavDropdown title="Ventas"  id="basic-nav-dropdown">
			      <NavDropdown.Item style={{marginTop:".7rem"}} className= "ddown_item" href='/#/api/ventas/casas'>

					Casas

			      </NavDropdown.Item>
			      <NavDropdown.Item style={{marginTop:".7rem"}}className= "ddown_item" href='/#/api/ventas/departamentos'>

						Departamentos
				</NavDropdown.Item>
			      <NavDropdown.Item  className= "ddown_item" style={{marginTop:".7rem"}} href='/#/api/ventas/oficinas'>
						Oficinas
				</NavDropdown.Item>
			      <NavDropdown.Item className= "ddown_item"  style={{marginTop:".7rem"}}href='/#/api/ventas/oficinas'>

						Estacionamientos
				</NavDropdown.Item>
			    </NavDropdown>
			    <Nav.Link >
				<Link style={{textDecoration:"none"}}className="linkSimple" to='/api/contacto'>Contacto y Sobre nosotros</Link>
			    </Nav.Link>
			</Nav>
		    
		    </Navbar.Collapse>
		    
		</Navbar>
        </DeviceIdentifier>
    </div>





    )




}




