import {setState, useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useRef } from "react";
import { useForm } from "react-hook-form";
import {Container, Button } from 'react-bootstrap';
import Blocked from '../components/blocked.js';


export default function AdminAgregarPagina(){
    let location = useLocation();
    const [propiedades, setPropiedades] = useState([]);
    const [numFotosPrincipales, setnumFotosPrincipales]= useState([1]);
    const [lugaresInteres, setLugaresInteres] = useState([0]);
    const [isLoaded, setIsLoaded] = useState("");
    const { register, handleSubmit} = useForm();
    const onSubmit = async (data) => {
        const formData = new FormData();
        
        for ( var i = 0 ; i < data.imagenPrincipal.length; i+=1){
            formData.append("imagenPrincipal", data.imagenPrincipal[i]);
        }
        for ( var i = 0 ; i < data.imagenesInterior.length; i+=1){
            formData.append("imagenesInterior", data.imagenesInterior[i]);
        }
        for ( var i = 0 ; i < data.imagenesExterior.length; i+=1){
            formData.append("imagenesExterior", data.imagenesExterior[i]);
        }
        for ( var i = 0 ; i < data.video.length; i+=1){
            formData.append("video", data.video[i]);
        }
        
        for (var i= 0; i< lugaresInteres.length; i+=1){
            formData.append("lugarInteres"+i, data["lugarInteres"+i]);
        }
        

        
        formData.append("nombre", data["nombre"]);
        formData.append("valor", data.valor);
        formData.append("superficie", data.superficie);
        formData.append("gastosComunes", data.gastosComunes);
        formData.append("banos", data.banos);
        formData.append("dormitorios", data.dormitorios);
        formData.append("comuna", data.comuna);
        formData.append("disponibilidad", data.disponibilidad);
        formData.append("estacionamientos", data.estacionamientos);
        formData.append("ubicacion", data.ubicacion);
        formData.append("ubicacionExtensa", data.ubicacionExtensa);

        formData.append("venta", data.venta);
        formData.append("arriendo", data.arriendo);
        formData.append("disponible", data.disponible);
        formData.append("descripcionCorta", data.descripcionCorta);
        formData.append("descripcionLarga", data.descripcionLarga);

        formData.append("tipoPropiedad", data.tipoPropiedad);

        const res = await fetch(location.pathname, {
            method: "POST", 
            headers:{
                "authorization": sessionStorage.getItem("token"),
                                    "provider":"anonymous",
                    "uid":sessionStorage.getItem("user")

            },
            body:formData
        }).then(res => res.json())
        .then(res =>{
            if (res.status =="success"){
                alert("todo listo, pagina subida")
            }
            if (res.status =="error subiendo la página"){
                alert("error. Revisar si todo lo obligatorio fue incluído")
            }}
        )
    };
    
    useEffect(() => {
        fetch(location.pathname, {
            method:"GET", 
            headers:{
                "authorization": sessionStorage.getItem("token")
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              if (result.message =="acceso restringido" || result.message =="token invalida"){
                  setIsLoaded("false")
              }
              if (result.message=="legal"){
                  setIsLoaded("true")
                  setPropiedades(result.propiedades);
              }

            }
          )
      }, [])


    

    const mostrarPropiedades=propiedades.map((propiedad, i)=>{
        return(
            <li id = {i}>{propiedad.nombre}</li>
        )
    })


    const mostrarInputLugaresInteres =  lugaresInteres.map((lugar, i)=>{
        return(
            <input {...register("lugarInteres"+i)} key = {i}>
            </input>
        )

    })

   
     function agregarInput(){
        setLugaresInteres([...lugaresInteres, lugaresInteres[lugaresInteres.legth]+1]);

    }

    if (isLoaded=="false"){
        return <Blocked></Blocked>
    }else if (isLoaded=="true"){   


    return(
        <div style={{margin:"30px"}}>
            <h1>Agregar pagina</h1>
            <div>
                <h2>Propiedades publicadas</h2>
                <ul>
                    {mostrarPropiedades}

                </ul>
            </div>
            
            
            <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label>nombre propiedad:</label>
                    <input  {...register('nombre')}  type="text" className="form-control"  ></input>
                </div>
                <div>
                  <label>valor propiedad (sin puntos ni comas ni $. ej: 100000):</label>
                    <input {...register('valor')} name = "valor" type="text" className="form-control"  ></input>
                </div>
                <div>
                  <label>superficie propiedad (solo el numero, sin m2. Si es casa, escribir contruido/total. Ej: 125/200):</label>
                    <input  {...register('superficie')}name = "superficie" type="text" className="form-control"  ></input>
                </div>
                
                <div>
                    <label>gastos comunes: (si es que no hay, dejar en 0)</label>
                    <input {...register('gastosComunes')} name = "gastosComunes" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>baños:</label>
                    <input {...register('banos')} name = "banos" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>dormitorios:</label>
                    <input {...register('dormitorios')} name = "dormitorios" type="text" className="form-control"  ></input>
                </div>
                <div>
                  <label>comuna: (intentar que cada comuna tenga el mismo nombre [ej: siempre escribir Santiago Centro y nunca Santiago])</label>
                    <input {...register('comuna')} name = "comuna"  type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>disponibilidad:</label>
                    <input {...register('disponibilidad')} name = "disponibilidad" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>estacionamientos: (si es que no hay, dejar en 0)</label>
                    <input {...register('estacionamientos')} name = "estacionamientos" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>ubicación (descripción extensa):</label>
                    <input {...register('ubicacionExtensa')} name = "ubicacionExtensa" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>ubicación (Ejemplo: Agustinas 888, Santiago Centro. Es muy importante poner el numero y la comuna(</label>
                    <input {...register('ubicacion')} name = "ubicacion" type="text" className="form-control"  ></input>
                </div>
                <div>
                    <label>¿en venta?</label>
                    <select {...register('venta')} name = "venta" className="form-control">

                        <option value=""></option>
                        <option value="true">sí</option>
                        <option value="false">no</option>
                    </select>
                </div>
                <div>
                    <label>¿en arriendo?</label>
                    
                    <select {...register('arriendo')} name = "arriendo" className="form-control">

                        <option value=""></option>
                        <option value="true">sí</option>
                        <option value="false">no</option>
                    </select>
                </div>
                
                <div>
                
                    <label>¿disponible?</label>
                    <select {...register('disponible')} name = "disponible" className="form-control">

                        <option value=""></option>
                        <option value="true">sí</option>
                        <option value="false">no</option>
                    </select>
                </div>
                <div>                    
                    

                    <label>tipo de propiedad</label>

                    <select {...register('tipoPropiedad')} name = "tipoPropiedad" className="form-control">

                        <option value=""></option>
                        <option value="casa">casa</option>
                        
                        <option value="departamento">departamento</option>
                        <option value="estacionamiento">estacionamiento</option> 
                        <option value="oficina">oficina</option>                       

                    </select>
                </div>

                <div className="form-group">
                    <label >imagen principal <b>(OBLIGATORIO)</b></label><br></br>
                    <input {...register('imagenPrincipal')} type="file" class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>imágenes interior <b>(OBLIGATORIO)</b></label><br></br>
                <input multiple type = "file" {...register('imagenesInterior')}  class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>imágenes exterior (opcional, si no hay, no subir nada)</label><br></br>
                <input multiple type = "file" {...register('imagenesExterior')}class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>video (opcional, si no hay, no subir nada)</label><br></br>
                <input  type = "file" {...register('video')} class="form-control-file" id="exampleformcontrolfile1"></input>
                </div>
                <div className="form-group">
                    <label>Lugares de interés</label><br></br>
                    {mostrarInputLugaresInteres}
                    <button   className="btn btn-primary" type="button" onClick={agregarInput} >Agregar lugar de interés</button>

                </div>

                <div>
                    <label >descripción (corta, ideal dos lineas.):</label>
                    <textarea {...register('descripcionCorta')} name = "descripcionCorta" className="form-control" rows="3"></textarea>
                </div>
                <div>
                    <label >descripción (larga):</label>
                    <textarea {...register('descripcionLarga')} name = "descripcionLarga" className="form-control" rows="3"></textarea>
                </div>
                
                
                <button className="btn btn-primary" style = {{margintop:"20px"}}>agregar página</button>
            </form>

            </div>



        </div>
    )
    }else {
        return <h1>Loading...</h1>
    }

}
