
import {Row, Container, Col}  from 'react-bootstrap';
import {Link} from 'react-router-dom';
import CarouselPrincipalMobile from '../components/carouselPrincipalMobile';
 
export default function AppMobile(props){



return(
    <div id = "paginaPrincipalFadeIn"  >
        <div  id = "paginaPrincipalMobileFondoSuperior">
            <div   >
            
                <Row className = "paginaPrincipalMobileFilaSuperior">
                    <h2 id = "paginaPrincipalTituloArriendosNuevos">Arriendos nuevos</h2>
                    
                    <CarouselPrincipalMobile id = "paginaPrincialCarouselSuperior"
                    datos = {props.datos.arriendos}>

                    </CarouselPrincipalMobile>
                </Row>
                <Row style={{fontFamily:"serif"}}className = "paginaPrincipalMobileFilaSuperior">
                    
                    <h2  style={{fontSize:"1.5rem"}}>Sobre nosotros</h2>
                    <ul style={{fontSize:".9rem"}}>
                        <li className="list-unstyled">30 años de experiencia</li>
                        <li className="list-unstyled">Dominio en materia legal</li>
                    </ul>
                    <h2 style={{fontSize:"1.5rem"}}>Nuestros servicios</h2>
                    <ul style={{fontSize:".9rem"}}>
                        <li className="list-unstyled">Corretaje de propiedades </li>
                        <li className="list-unstyled">Arriendos</li>
                        <li className="list-unstyled">Ventas</li>
                        <li className="list-unstyled">Casas, Departamentos, Oficinas y Estacionamientos</li>

                    </ul>
                    
                </Row>
            </div>      
        </div>
     

        <div id = "paginaPrincipalMobileContenedorVentasPaginaPrincipal">
            <Row id = " paginaPrincipalMobileContenedorBlancoIzquerdoInferior"style={{paddinig:"0px",backgroundColor:"white", color:"grey"}}>
                
                <h1 id="paginaPrincipalTituloVentasNuevas" style={{fontSize:"1.5rem", fontFamily:"serif"}} className = "textoAzul" > Ventas nuevas</h1>
            
                <CarouselPrincipalMobile id="paginaPrincialCarouselInferior"
                datos = {props.datos.ventas}>
                </CarouselPrincipalMobile>
            
            </Row>
            <Row id = "paginaPrincipalMobileContenedorBlancoIzquerdoInferior">
                <h1 id="paginaPrincipalTituloVentasNuevas" style={{fontSize:"1.5rem", fontFamily:"serif"}} className = "textoAzul" > Oficina</h1>
                <div style={{width:" 100%", height:"250px"}}>
                    <iframe  src="https://maps.google.com/maps?width=100%25&amp;height=800&amp;hl=en&amp;q=Padre%20Mariano%20210,%20providencia,%20Santiago+(Oficina)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" style={{width:"100%", height:"100%"}}></iframe>
                    
                </div>
                    
            
                <h5 style={{marginTop:'15px',fontSize:"1.5rem", fontFamily:"serif"}} >Padre Mariano 210,<br/> Oficina 408.</h5>
                <p style={{fontSize:".9rem", fontFamily:"serif"}}>
                09:30 - 18:00
                </p>
                <p style={{fontSize:".9rem", fontFamily:"serif"}}>
                +56 2 2341 8815
                </p>
            </Row>
            <div id = "paginaPrincipalMobileContenedorFaqs">
                <div id = "paginaPrincipalMobileContenedorLinksFaqs">
                    <h1>
                        <Link style={{color:"white",textDecoration:"none"}} to="/api/faqs">Preguntas frecuentes</Link>
                    </h1>
                </div>
            </div>

        </div>
        <div>
            
      </div>
    </div>

)


}
