import {Carousel, Button, Row, Col, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useEffect } from 'react';


export default function CarouselPrincipalMobile(props){
    const mostrarPropiedades = props.datos.map((propiedad, i)=>{
        return(
            <Carousel.Item key={i} >

             <Link style={{"textDecoration":"none"}} to ={"/api/"+ propiedad.tipoCarousel +"/" + propiedad.nombre}>
                <div className = "paginaInicialContenedorImagenAvisoMobile" >
                    <img
                    className="paginaPrincialImagenAvisoMobile"
                    src={propiedad.linkImagenPrincipal}
                    alt={i + " slide"}
                    key = {i}
                    
                    />
                </div>
                
                <div className="paginaPricipalContenedorInformacionCarousel" >
                    <Row >
                        <Col style={{textAlign:"center", width:"100%"}}>
                        <h3 style={{fontSize:"1.2rem", fontFamily:"serif"}}>{propiedad.nombre}</h3>
                    <p style={{fontSize:".8rem"}} >{propiedad.comuna}</p>
                        </Col>
                    </Row>
                    
                    
                    
                </div>

                            </Link>
            </Carousel.Item>
        )
    })


    if (props.datos.length==0){
        return(
            <div className="contenedorNoDisponibleCarousel">
                <h4>Propiedades no disponibles</h4>
            </div>
        )
    }else {
        return(
            <Carousel   nextLabel={" "} prevLabel={" "}>
                {mostrarPropiedades}

            </Carousel>

        )
    }




}
