import {Container,Row, Col} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha"
import {useState} from 'react';





export default function ContactoMobile(){
    const [isHuman, setIsHuman] = useState(false);

    const location = useLocation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    async function onCapChange( value) {
	let bod = {llave:value}
	 let status =await fetch(location.pathname+"/captcha",{
            method:"POST",
	    headers: {
      'Content-Type': 'application/json'
    		},
            body:JSON.stringify(bod)
        } )
	.then(res => res.json()) 
	.then(res =>{
		if(res["success"] == true){
			setIsHuman(true)

		}else{
			setIsHuman(false)
		}	
	})
     }
     const mostrarSubmit = (isHuman)=>{
	if(isHuman){
		return(
		<input className="btn btn-primary buttonForma" value="enviar"type='submit' />	
		)
	}else{
		return(
		<input className="btn btn-primary buttonForma" value="enviar"type='submit' disabled />	
		
		)	
	}

      }



    const onSubmit = async (data)=>{
        const formData= new FormData();
        formData.append("nombre", data.nombre);
        formData.append("email", data.email);
        formData.append("propiedadInteres", data.propiedadInteres);
        formData.append("telefono", data.telefono);
        formData.append("mensaje", data.mensaje);
        const res = await fetch(location.pathname,{
            method:"POST",
            body:formData
        } ).then(
            res =>res.json()
        ).then(res=>{
            alert(res.status)
        }
        )

    }

    return(
        
        <Container>
        <Container id = "contenedorAbout">
            <h1>Sobre nosotros</h1>
                <p>Desde 1991 prestando servicios profesionales  en gestión inmobiliaria en ámbito profesional, para la adquisición de inmuebles , gestión integral de su  arriendo   y  administración de su propiedad,  como también su colocación en venta del mismo , respaldado en  la calidad de Abogado de la Universidad Católica de Chile,  pos título en UTEM en tributario, y Tasador de la escuela de Arquitectura de la Universidad de Chile, me permite minimizar sus  riesgos desde la  valoración de inmuebles para venta y arriendo, y generar todo gestión necesaria para su tranquilidad.</p>
            <Row>
                
            <h2>Contacto</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <input className="inputFormaMobile" type="text" placeholder="nombre" {...register("nombre")} required />
                        <input  className="inputFormaMobile" type="email" placeholder="correo (nos contactaremos con usted por este medio)" {...register("email")} required/>
                       
                        <input  className="inputFormaMobile" type="text" placeholder="propiedad que me interesa" {...register("propiedadInteres")}required />
                        
                        <input  className="inputFormaMobile" type="text" placeholder="telefono" {...register("telefono")} required />
                        
                        <textarea  className="inputFormaMobile" placeholder="mensaje" {...register("mensaje")}required />
   			<div className="recaptchaContainerMobile"  >
<ReCAPTCHA size={"compact"} sitekey="6LfxFg8eAAAAAOSFD2W-AckjaWEpypzBPzxQjm_H" onChange={onCapChange} />

			</div>
                       	<div>
		{
			mostrarSubmit(isHuman)
		}

		</div>
	
                        </form>

            </Row>
            <Row style={{marginTop:"1rem"}}>
                <Row>
                <div id="gmap_canvas" >
                    <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=padre%20mariano%20201%20providencia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>

                </div>
                </Row>
                <Container style={{padding:'1rem', textAlign:"left"}}>
                    
                Dirección: Padre Mariano 210, Oficina 408, Providencia, Región Metropolitana.<br></br>
                Correo Contacto: oyarzun.propiedades@yahoo.com<br></br>
                Teléfonos:<br></br>
                223418815<br></br>
                222443634<br></br>
                horarios de atención: <br></br>
                lunes a viernes de 9:30 a 14:00 y de 15:00 a 18:00<br></br>
                </Container>
                    
                

            </Row>
            
        
        </Container>



    </Container>
        
    )






}
