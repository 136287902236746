import { Marker, GoogleMap, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";
import {useState} from 'react';

export default function Mapa(props){
  Geocode.setApiKey("AIzaSyC4n7uJ-_lYZ94LtqISBKbi5tfqjilFI8A");
	Geocode.setLanguage("es");
  const [lat, setLat]= useState(0);
  const [lon, setLon]= useState(0);

  Geocode.fromAddress(props.direccion).then(
  (response) => {
    const { lat, lng } = response.results[0].geometry.location;
    setLat(lat);
    setLon(lng);
  },
  (error) => {
  }
);


const containerStyle = {
  width: '100%',
  height: '350px'
};
const center = {
  lat: lat,
  lng: lon
};
  return (
    <div>
<LoadScript
        googleMapsApiKey="AIzaSyC4n7uJ-_lYZ94LtqISBKbi5tfqjilFI8A"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
	language={"es"}
        >
          <Marker position={center}/>
        </GoogleMap>

      </LoadScript>
    </div>

  )


}
