import Propiedad from  '../components/propiedad.js';
import {Col, Row,Container, Accordion, Collapse, Button} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import axios from 'axios';

import Blocked from '../components/blocked.js';


export default function AdminEliminarPagina(){
    let location = useLocation();

    const [datos, setDatos] = useState([])
    const [eliminar, setEliminar]= useState([])
    const [isLoaded, setIsLoaded] = useState("");

    useEffect(() => {
        fetch(location.pathname, {
            method:"GET", 
            headers:{
                "authorization": sessionStorage.getItem("token"),
                                  "provider":"anonymous",
                    "uid":sessionStorage.getItem("user")

            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              if (result.message =="acceso restringido" || result.message =="token invalida"){
                  setIsLoaded("false")
              }
              if (result.message=="legal"){
                  setIsLoaded("true")
                  setDatos(result.propiedades);
              }
            }
          )
      }, [])
   
      
      
      function refreshPage() {
        window.location.reload(false);
      }

      function agregarLista(nombre,eliminar){
        
        setEliminar([...eliminar, nombre]);
        let newList = [];
        for (let i = 0; i < datos.length; i++) {
            if (datos[i].nombre != nombre){
                newList.push(datos[i]);
            }
        }
        setDatos(newList);  
      }
      async function handleRemove(eliminar) {
        let lista = [];
        for (var i = 0 ; i< eliminar.length; i++){
            lista.push(eliminar[i]);
        }

        let borrar= {"nombres":lista}
        const res = await axios({
            method:"DELETE",
            url:location.pathname, 
            headers:{
                "authorization":sessionStorage.getItem("token")
            }, 
            data:{"nombres":lista}

        }).then(window.location.reload())
      
      }




      const mostrarPropiedades = datos.map((dato,i)=>{
        if (datos.length ==0){
            return(
                <div>
                    
                    <h2>No hay propiedades en la lista.</h2>
                </div>
            )
        }else{
            return(
                <Row>
                <Col xs={10}>
                  <Container  className= "contenedorTabla">
                  <h3>{dato.nombre}</h3>
                  Ubicacion: {dato.ubicacion}<br></br>
                  Valor: {dato.valor}
                  </Container>
                </Col>
                <Col className= "contenedorBorrar">
                    <Button onClick = {() => agregarLista(dato.nombre,eliminar)} className="btn btn-danger btn-lg">Eliminar</Button>
                </Col>
                </Row>
                )
            }
        }
    )
    if (isLoaded=="false"){
        return <Blocked></Blocked>
    }else if (isLoaded=="true"){   

    return(
        <div style ={{margin:"10px"}}>
            <h1>Eliminar pagina</h1>
            <Container>
                
                <Button style={{margin:"10px"}} onClick={()=>handleRemove(eliminar)}>Guardar Cambios</Button>
                <Button style={{margin:"10px"}} onClick={refreshPage}>Deshacer Cambios</Button>

            </Container>
            
            
            <Row>{mostrarPropiedades}</Row>

        </div>
    )
    }else {
        return <h1>Loading...</h1>
    }

}
